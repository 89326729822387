.input {
    background: #FFFFFF !important;
    border: 1px solid #D3D9E0 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
    height: 40px !important;


}

.input .ant-input-group .ant-input {
    height: 40px !important;
}

.input:focus {
    background: #ffffff !important;
    border: none !important;
    box-sizing: border-box !important;
    border-radius: 2px !important;
}

.has-error .ant-input:not([disabled]) {
    border-color: #ff244e !important;
}

.inputLabel {
    font-family: Inter !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    display: block !important;
    color: #2D3748;
}

.ant-form-item-explain-error {
    margin-top: 8px !important;
}
