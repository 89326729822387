@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

body {
    background: #1A202C;
    font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.text-center{ text-align: center}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.text-bold {
    font-weight: 700;
}
.text-center {
    text-align: center;
}
.av-settings-popover .ant-popover-inner {
    width: fit-content !important;
}

.plyr {
    height: 100vh !important;
}

.ant-modal-body {
    width: 450px;
}

.ask-question-status-bar-timing {
    font-size: 28px;
    margin-right: 15px;
}

.video-player-with-status-bar .plyr__controls {
    bottom: 62px !important;
    width: 100% !important;
    padding-right: 100px !important;
}

.video-player .plyr__controls {
    bottom: 0 !important;
    width: 100% !important;
    padding-right: 100px !important;
}

/* Grid */
.row {
    margin-left: -1rem;
    margin-right: -1rem;
}

.col-6 {
    width: 50%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.col-4 {
    width: 25%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.breakout-specific-col {
    padding-right: 1rem;
}
